<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="howOftenOverall"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <p class="subtitle">
      A seven point rating scale for assessing the frequency with which people show the certain behaviours. Rate each description on how
      often it that behaviour normally occurs.
    </p>
    <template v-for="item in form.items">
      <b-field :key="item.lookupId" :label="lookupName(item.lookupId)" horizontal class="label-long">
        <enum-drop-down v-model="item.howOften" :data="selectLookup" smallWidth :disabled="readonly" />
      </b-field>
    </template>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import EnumDropDown from "@/components/EnumDropDown";
import _ from "lodash";

import AssessmentType from "@/enums/assessmentType";
import assessmentMixin from "@/mixins/assessmentMixin";

import lookups from "@/enums/lookups.js";
import cohen from "@/enums/cohen.js";

const CohenLookUpStart = 150;
const CohenNumValues = 29;

export default {
  components: { AssessmentForm, EnumDropDown },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.CohenMansfield,
      lookupType: lookups.Enum.CohenMansfield,
      selectLookup: cohen.SelectLookup,
      form: this.getClearFormObject()
    };
  },

  computed: {
    cohenItems() {
      var values = this.$store.getters["lookups/getLookups"]({ lookupType: this.lookupType });
      if ((!values || !values.length) && !this.fetchingData) {
        this.getData();
      }
      return values;
    },

    score() {
      return this.form.items.reduce((t, i) => t + parseInt(i?.howOften ?? 0), 0);
    },
    howOftenOverall() {
      let overall = Math.floor((this.score - 1) / CohenNumValues) + 1;
      if (overall === 0) overall = 1;
      return overall;
    }
  },
  watch: {
    cohenItems: "initItems"
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: this.lookupType });
      this.fetchingData = false;
    },
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        items: this.getClearFormItems()
      };
    },
    initItems() {
      if (!this.form.items.length) this.form.items = this.getClearFormItems();
    },
    getClearFormItems() {
      return _.range(0 + CohenLookUpStart, CohenNumValues + CohenLookUpStart).map(r => {
        return { lookupId: r };
      });
    },
    lookupName(id) {
      return this.cohenItems.find(i => i.lookupId === id)?.name ?? "";
    }
  }
};
</script>
